import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Error from "../img/error.svg";
import { getLanguage, getData } from "../helper/helper";

const locale = getLanguage();
const page = getData(locale).page404;

const NotFoundPage = () => (
  <Layout locale={locale}>
    <div className="container">
      <div className="page-not-found">
        <Error />
        <h1>{page.title}</h1>
        <p>{page.content}</p>
        <Link to={page.url}>{page.button}</Link>.
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
